import axios from "axios";

const apiUrl = process.env.VUE_APP_API_URL;
const appId = "octilliontv";

console.log('API_URL:', apiUrl)

export default {
  state: {
    loggedInUser:
      localStorage.getItem("userInfo") != null
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null,
    apiUrl: apiUrl,
    appId: appId,
    userKey: 
      localStorage.getItem("userKey") != null
        ? localStorage.getItem("userKey")
        : null,
    loading: false,
    error: null
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    apiUrl: state => state.apiUrl,
    appId: state => state.appId,
    userKey: state => state.userKey,
    loading: state => state.loading,
    error: state => state.error
  },
  mutations: {
    setUser(state, data, userKey) {
      state.loggedInUser = data;
      state.userKey = userKey;
      state.loading = false;
      state.error = null;
    },
    setLogout(state) {
      state.loggedInUser = null;
      state.userKey = null;
      state.loading = false;
      state.error = null;
      // this.$router.go("/");
    },
    setLoading(state, data) {
      state.loading = data;
      state.error = null;
    },
    setError(state, data) {
      state.error = data;
      state.loggedInUser = null;
      state.userKey = null;
      state.loading = false;
    },
    clearError(state) {
      state.error = null;
    }
  },
  actions: {
    login: ({ commit }, user) => {
      commit("clearError");
      commit("setLoading", true);

      axios({
        url: `${apiUrl}/rest/users/authenticate`,
        data: user,
        method: "POST",
      })
        .then((response) => {
          const userKey = response.data.apiKey;
          const userInfo = response.data.user;

          localStorage.setItem("userKey", userKey);
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          if (userInfo.super_user === true) {
            commit("setUser", userInfo, userKey);
          } else {
            commit("setError", 'You are not allowed to access this page.');
          }
        })
        .catch((err) => {
          commit("setError", err);

          localStorage.removeItem("userKey");
          localStorage.removeItem("userInfo");
        });
    },

    signOut: ({ commit }) => {
      commit("setLogout");

      localStorage.removeItem("userKey");
      localStorage.removeItem("userInfo");
    }

  }
};
