<template> 
  <!-- eslint-disable vue/no-template-shadow -->
  <Subscription
    v-slot="{ data }" 
    :query="query"
  >        
    {{ refresh(data) }}
    <!-- eslint-disable vue/no-template-shadow -->
    <div v-if="data && hasJob">      
      {{ data.jobStatus.message }}
    </div>

    <!-- eslint-disable vue/no-template-shadow -->
    <Query 
      v-if="!data && hasJob"
      v-slot="{ data }"
      query="query jobsById($id: String!) {
        jobById(id: $id) {
          id,
          message
        }
      }"
      :variables="{id: findId}" 
    >
      <!-- eslint-disable vue/no-template-shadow -->
      <div v-if="data">
        <!-- eslint-disable vue/no-template-shadow -->
        <slot
          name="queryContainer"
          :prop="{ queryData: data}"
        />
      </div>
    </Query>
  </Subscription>
</template>

<script>  

import { Subscription, Query } from 'vue-gql'
 
export default { 
    name: "Subscribe", 
    components: {
      Subscription,
      Query
    },
    props: {
      hasJob: {type: Boolean, default: false},
      findId: {type: String, default: ""},
      value: {type: Object, default: null},
      keys: {type: Array, default: () => { return []}},
      query: {
        type: String,
        default: ''
      }, 
    },
    data() {      
      return {      
        data: null, 
      }
    },
    mounted() { 
      this.refresh(null);
    },
    methods: {
      refresh(data) {
        if (data != this.data) {
          this.data = data;
          this.refill();
        }        
      },
      refill() { 
        if (this.keys.length > 0) {
          let output = _.cloneDeep(this.data);
          if (output) {
            _.forEach(this.keys, function(key) {            
              if (output != null) {
                output = (_.has(output, key)) ?  output[key] : null;
              }
            });
            this.$emit('input', output);
          }
        } else {
          this.$emit('input', this.data);
        } 
      }
    }
}
</script>