<script>
import axios from "axios";
import store from "@/store";
import moment from 'moment';

export default {
  data() {
    return {
      instance: null,      
      parameters: {},
      lineChartConfig: {
        series: [
          /*{
            name: 'Income',
            data: [14, 29, 18, 20, 20, 40, 20],
          }*/
        ],
        chartOptions: {
          chart: {
            type: 'line',
            height: 200,
            toolbar: {
              show: true,
            },
          }, 
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          colors: ['#5d78ff', '#fbaf0f', '#ff5252', '#ff8a48',"#304156", '#09b66d', '#00dcb9', '#c695ed', '#ffdcff', '#ff96c0', '#09b66d', '#908cab', '#304156', '#359ab9', '#42ffff'],
          stroke: {
            width: 2,
            curve: 'smooth',
          },
          xaxis: {
            categories: [],
            //categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'], 
            title: {
              text: '',
            },
            axisBorder: {
                show: false,
              },
          },
          yaxis: {
            title: {
              text: '',
            },
          },
          markers: {
            size: 3,
            hover: {
              size: 6,
            },
          },
          grid: { 
            borderColor: 'transparent',
            row: {
              colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0,
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5,
          },
        }
      },
      pieChartConfig: {
        chart: {
          width: '100%',
        },
        series: [44, 55, 41, 17, 15],
        chartOptions: {
            legend: {
            position: 'bottom',
          },
          labels: ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5'],          
         // colors: ['#505D6F', '#826AF9', '#2CD9C5', '#2D99FF', '#FF6C40', '#FFE700'],
          grid: {
            padding: {
              top: 20,
              right: 0,
              bottom: 20,
              left: 0
            }
          },
        }
      },
      barChartConfig: {
        series: [
          /*{
            name: 'Organic',
            data: [11, 100, 80, 20, 51, 42, 109],
          }, */
        ],
        chartOptions: {
          chart: {
            height: 200,
            type: 'bar',
            toolbar: {
              show: true,
            },
          },
          plotOptions: {
            bar: {
              startingShape: 'flat',
              endingShape: 'rounded',
              columnWidth: '40%',
            },
          },
          stroke: {
            curve: 'smooth',
          },
          dataLabels: {
            enabled: false,

            offsetY: -10,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },
          grid: {
            borderColor: 'transparent',
            row: {
              opacity: 0,
            },
          },
          colors: ['#5d78ff', '#fbaf0f', '#ff5252', '#ff8a48',"#304156", '#09b66d', '#00dcb9', '#c695ed', '#ffdcff', '#ff96c0', '#09b66d', '#908cab', '#304156', '#359ab9', '#42ffff'],
          xaxis: {
            categories: ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5'],
            labels: {
              show: true,
              rotate: 0,
              rotateAlways: false,
              hideOverlappingLabels: true,
              trim: false
              
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },

        },
      },
      customChartConfig: {
        series: [
          /*{
            name: 'Organic',
            data: [11, 100, 80, 20, 51, 42, 109],
          }, */
        ],
        chartOptions: {
          chart: {
            height: 200, 
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              startingShape: 'flat',
              endingShape: 'rounded',
              columnWidth: '40%',
            },
          },
          stroke: {
            curve: 'smooth',
            width: 1,
          },
          dataLabels: {
            enabled: false,

            offsetY: -10,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },
          grid: {
            borderColor: 'transparent',
            row: {
              opacity: 0,
            },
          },
          colors: ['#5d78ff', '#fbaf0f', '#ff5252', '#ff8a48',"#304156", '#09b66d', '#00dcb9', '#c695ed', '#ffdcff', '#ff96c0', '#09b66d', '#908cab', '#304156', '#359ab9', '#42ffff'],
          xaxis: {
            categories: ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5'],
            labels: {
              show: true,
              rotate: 0,
              rotateAlways: false,
              hideOverlappingLabels: true,
              trim: false
              
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },

        },
      },
      showFilter: false,
      booleans: [{id:true, name: 'True'}, {id:false, name: 'False'}],
      feeTypes: [{id:`Percent`, name: `Percent`}, {id: 'CPM', name: 'CPM'}],
      listParameters: {
        fields: [],
        sources: {
          main: "",
          children: []
        },
        wheres: [],
        groups: [],
        orders: [],
        paging: {
          page: 1,
          page_size: 20,
        },
      },
      addParameters: {
        "action": "insert",
        "fields": [
          /*{
            "field": "name",
            "value": "alex"
          }*/
        ], 
        "table": ""	
      },
      updateParameters: {
        "action": "update",
        "fields": [
          /*{
            "field": "name",
            "value": "alex"
          }*/
        ], 
        "table": "",
        "wheres": [
          /*{
            "field": "id",
            "value": 139
          }*/
        ]
      },
      deleteParameters: {
        "action": "delete", 
        "table": "",
        "wheres": [
          /*{
            "field": "id",
            "value": 139
          }*/
        ]
      },
      accounts: [],
      advertisers: [],
      campaigns: [],
      creatives: [],
    }
  },
  async mounted() {    
    const apiUrl = process.env.VUE_APP_API_URL; // store.getters.apiUrl;
    this.instance = axios.create({ baseURL: apiUrl });
    this.instance.interceptors.request.use(
      (config) => {        
        
        //const appId = "octilliontv";
        //const apiKey = localStorage.getItem("userKey");

        const appId =  process.env.VUE_APP_API_APP_ID; 
        const apiToken = localStorage.getItem("userKey");
        const apiKey =  process.env.VUE_APP_API_KEY; 
        config.headers["x-app-id"] = appId;
        if (!apiToken && apiKey) {
          config.headers["x-api-key"] = apiKey;
        }
        else {
          config.headers["x-api-token"] = apiToken;
        }
        
       
        if (config.method == "put" || config.method == "post") {
          let payloadData = config.data;
          if (
            payloadData != null &&
            payloadData != undefined &&
            (payloadData.deleted_at == "" || payloadData.deleted_at != "")
          ) {
            delete payloadData.deleted_at;
          }
          config.data = payloadData;
        }
        // let refineParam = {};
        // if(config.params) {
        //     Object.keys(config.params).forEach(ele => {
        //         refineParam[ele] = customEncode(config.params[ele]);
        //     });
        // }
        // config.params = refineParam;
        return config;
      },
      (error) => { 
        Promise.reject(error);
      }
    );
    this.instance.interceptors.response.use(
      (response) => {
        // Do something with response data
        return response;
      },
      (error) => {
        if ([401, 403].includes(error.response.status)) {
            // redirect to login page
            localStorage.removeItem('userInfo')
            localStorage.removeItem('userKey')
            localStorage.removeItem('ttl')
            console.log('here')
            window.location.href = "/app/sessions/login";
        }        
        return Promise.reject(error.response)
      }
    );
    // checking for valid session
    const ttl = localStorage.getItem("ttl") != null
        ? new Date(localStorage.getItem("ttl"))
        : null;
    if (!ttl || (ttl && ttl < new Date())) {
      const data = await this.getList(
        ["ilvw.*"],
        `(select current_timestamp + interval '5 minutes' as ttl) ilvw`,
        [],
        [],
        "ttl" 
      )
      if (data && data.length > 0) {
        localStorage.setItem("ttl", new Date(data[0].ttl));
      }  
    }
  },
  methods: { 
    customEncode(pictureUrl) {
      var map = {
        "&": "%26",
        "<": "%3c",
        ">": "%3e",
        '"': "%22",
        "'": "%27",
      };
    },
    async deleteData(url, payload) {
      return await this.instance.delete(url, payload);
    },
    async postData(url, payload) { 
      return await this.instance.post(url, payload);
    },
    async getData(url, payload) {
      return await this.instance.get(url, payload);
    },
    async putData(url, payload) {
      return await this.instance.put(url, payload);
    },
    async getList(fields, table, wheres, groups, order) {
      const url = `/rest/intranet/list?ts=${(new Date()).getTime()}`;
      const payload = {
        fields: fields,
        sources: {
          main: table,
          children: []
        },
        wheres: wheres,
        groups: groups,
        orders: [{
          sort: order,
          dir: "ASC"
        }]
      };
      return await this.postData(url, payload).then((response) => {
        return response.data.results;
      });
    },
    async getMax(table, field) {
      const rows = await this.getList(
        [`COALESCE(max(${field}) + 1, 100001) as ${field}`],
        table,
        [],
        [],
        field
      );
      if (rows.length > 0) {
        return rows[0][field];
      } else {
        return 1;
      }
    },
    getAPIUrl() {
      return process.env.VUE_APP_API_URL;
    },
    getFloat(value) {
      if (!value) return '0.00';
      if (!isNaN(value)) { 
        return this.numberFormat(value || 0, 2, '.', ',') //parseFloat(parseFloat(value).toFixed(2)).toLocaleString() || '0.00';
      }
      return '0.00';
    },
    getDate(value) {
      if (!value) return '';
      return moment(value).utc().format('YYYY-MM-DD');
      //return (new Date(value)).toLocaleDateString()
    },
    getDateTime(value) {
      if (!value) return '';      
      return moment(value).utc().format('YYYY-MM-DD HH:mm:ss');
      //return `${(new Date(value)).toLocaleDateString()} ${(new Date(value)).toTimeString().substring(0,8)}`
    },
    getStringArray(value) {
      if (Object.prototype.toString.call(value) == '[object Array]') {     
        const arr = [];
        for (let i = 0; i < value.length; i++) {
          arr.push(`${i+1}. ${value[i]}`)
        }
        return arr.join('<br>');
      }
      return ''
    },
    getInteger(value) {
      if (!value) return '0';
      return parseInt(value.toString()).toLocaleString() || '0';
    },
    getDecimal(value) {
      if (!value) return '0.00';
      if (!isNaN(value)) { 
        let ret = this.numberFormat(value || 0, 2, '.', ',')
        return `${ret}`
      }
      return '0.00';
    },
    getPercent(value) {
      if (!value) return '0.00%';
      if (!isNaN(value)) { 
        let ret = this.numberFormat(value || 0, 2, '.', ',')
        return `${ret}%`
      }
      return '0.00%';
    },
    getCurrency(value) {
      if (!value) return '$0.00';
      if (!isNaN(value)) { 
        let ret = this.numberFormat(value || 0, 2, '.', ',')
        return `$${ret}`
      }
      return '$0.00';
    },
    getHeight(adjust = 0) {
      return `${window.innerHeight - adjust}px`;
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
      const n = !isFinite(+number) ? 0 : +number
      const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
      const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
      const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
      let s = ''
      const toFixedFix = function (n, prec) {
        if (('' + n).indexOf('e') === -1) {
          return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
        } else {
          const arr = ('' + n).split('e')
          let sig = ''
          if (+arr[1] + prec > 0) {
            sig = '+'
          }
          return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
        }
      }
      // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
      s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
      }
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1).join('0')
      }
      return s.join(dec)
    },
    async changeQueryString(queryString) {
      try {        
        await this.$router.push({path: this.$route.fullPath, query: queryString});
      } catch (ex) {
        
      } 
    },
    async clearQueryString() {
      try {        
        await this.$router.replace({query: null});
        //await this.$router.push({path: this.$route.fullPath, query: queryString});
      } catch (ex) {
        
      } 
    },
    getWhereString(field, value) {
      return `${field} ilike '%${value.toString().replace(/'/g, "''")}%'`;
    },
    stringToArray(value) {
      return (Object.prototype.toString.call(value) === '[object String]') ? 
          [value] : value
    },
    stringToNumberArray(value) {
      const output = (Object.prototype.toString.call(value) === '[object String]') ? 
          [value] : value;
      return output.map((d) => Number(d))
    },
    stringToStringArray(value) {
      const output = (Object.prototype.toString.call(value) === '[object String]') ? 
          [value] : value;
      return output.map((d) => `'${d}'`)
    },
    stringToBooleanArray(value) {
      const output = (Object.prototype.toString.call(value) === '[object String]') ? 
          [value] : value;
      return output.map((d) => Boolean(d))
    },
    setBusy(value) {   
      if (value.busy) this.busy = value.busy;
      if (value.busy === false) this.busy = false;
    },   
    async getAdvertisers(item) {
      if (!(Object.prototype.toString.call(item) === '[object Array]')) {
        item = [item];
      }
      if (item.length > 0) {
        this.advertisers = await this.getList(
          ["advertiser_id as id, advertiser_name as name"],
          "advertisers",
          [`account_id in (${item.join(`,`)})`],
          [],
          "advertiser_name"
        );
      } else {
        return [];
      }
    },
    async getCreatives(account_id, advertiser_id) {
      if (!(Object.prototype.toString.call(account_id) === '[object Array]')) {
        account_id = [account_id];
      }
      if (!(Object.prototype.toString.call(advertiser_id) === '[object Array]')) {
        advertiser_id = [advertiser_id];
      }
      this.creatives = await this.getList(
        ["creative_id as id, creative_name as name"],
        "creatives",
        [`account_id in (${account_id.join(`,`)}) and advertiser_id in (${advertiser_id.join(`,`)})`],
        [],
        "creative_name"
      );
    },
  },
};
</script>
