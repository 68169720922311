<template>
  <div class="alex" style="padding:7px;">
    <v-row>
      <v-col cols="12" :class="isBusy ? '' : 'hidden'">
        <v-skeleton-loader
          type="table"
          transition="v-scroll-x-transition"
          class="button-icon-skeleton"
        />
      </v-col>
      <v-col cols="12" :class="!isBusy ? '' : 'hidden'">
        <v-data-table
          ref="octillionTable"
          :headers="headers"
          :items="currentData.filter((d)=> !d.hidden )"
          :fixed-header="currentFixedHeader"
          hide-default-footer
          :disable-sort="!hasSorting"
          :disable-filtering="true"
          :disable-pagination="true"
          :options="{ itemsPerPage: -1 }"
          class="elevation-1 o-table"
          :height="currentHeight"
        >
          <template #header>
            <slot name="header" />
          </template>
          <!-- eslint-disable-next-line -->
          <template
            v-for="(head, i) in headers"
            v-slot:[`item.${head.value}`]="{ item }"                        
          >          
            <!-- eslint-disable-next-line -->
            <div :class="[isDeleted(item) ? 'deleted' : '']" :key="i">
              <div v-if="head.value === 'action'" >
                <div class="d-flex">
                  <v-tooltip v-for="(action, j) in head.actions" :key="j" top>
                    <template v-slot:activator="{ on, attrs }">
                      <o-button 
                        v-if="(!(isDeleted(item) && action.label === `Delete`))"
                        class="ml-2"
                        :color="action.color"
                        :icon="action.icon"
                        v-bind="attrs"
                        v-on="on"
                        type="right-label-icon"
                        :label="`${action.label}`"
                        @on-click="onEmit(action.key, item)"
                      /> 
                      
                    </template> 
                  </v-tooltip>
                </div>
              </div>
              <div v-if="head.type === 'edit-boolean'" :key="i">
                <o-check-box
                  v-model="item[head.value]"
                  label=""
                  @on-change="
                    onEmit(head.action, { key: head.value, value: item })
                  "
                />
              </div>
              <div
                v-if="head.type === 'text'"
                :key="i"
                class="custom-no-wrap"
              >
                {{ item[head.value] ? item[head.value].toString() : "" }}
              </div>
              <div v-if="head.type === 'boolean'" :key="i">
                <v-chip
                  v-if="
                    item[head.value] == null || item[head.value] == undefined
                  "
                  class=""
                  color="warning"
                  label
                  small
                  text-color="white"
                >
                  <v-icon small left>
                    mdi-close
                  </v-icon>
                  Null
                </v-chip>

                <v-chip
                  v-else-if="Boolean(item[head.value])"
                  class=""
                  color="success"
                  label
                  small
                  text-color="white"
                >
                  <v-icon small left>
                    mdi-check
                  </v-icon>
                  Yes
                </v-chip>
                <v-chip
                  v-else-if="!Boolean(item[head.value])"
                  class=""
                  color="danger"
                  label
                  small
                  text-color="white"
                >
                  <v-icon small left>
                    mdi-close
                  </v-icon>
                  No
                </v-chip>
              </div>
              <div
                v-if="head.type === 'int'"
                :key="i"
                class="text-right custom-no-wrap"
              >
                {{
                  item[head.value]
                    ? parseInt(item[head.value].toString()).toLocaleString()
                    : ""
                }}
              </div>
              <div v-if="head.type === 'decimal'" :key="i" class="text-right">
                {{ getFloat(item[head.value]) }}
              </div>
              <div
                v-if="head.type === 'percent'"
                :key="i"
                class="text-right custom-no-wrap"
              >
                {{ item[head.value] ? `${getFloat(item[head.value])}%` : "" }}
              </div>
              <div
                v-if="head.type === 'currency'"
                :key="i"
                class="text-right custom-no-wrap"
              >
                {{ item[head.value] ? `$${getFloat(item[head.value])}` : "" }}
              </div>
              <div
                v-if="head.type === 'date'"
                :key="i"
                class="text-left custom-no-wrap"
              >
                {{ item[head.value] ? getDate(item[head.value]) : "" }}
              </div>
              <div
                v-if="head.type === 'date-time'"
                :key="i"
                class="text-left custom-no-wrap"
              >
                {{ item[head.value] ? getDateTime(item[head.value]) : "" }}
              </div>
              <div
                v-if="head.type === 'string-array'"
                :key="i"
                class="text-left custom-no-wrap"
                style="font-family: monospace"
                v-html="(item[head.value] ? getStringArray(item[head.value]) : '')"
              >
              </div>
              <div
                v-if="head.type === 'slot'"
                :key="i"
                :class="[getAlignment(head.align), 'custom-no-wrap']"
              >
                <slot name="slot" :prop="{ item: item, key: head.value }" />
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>

      <v-col
        cols="4"
        offset="4"
        :class="[!isBusy && !noCount ? '' : 'hidden', 'text-center']"
      >
        <small>Count : {{ paging.totalItems.toLocaleString() || "" }}</small>
      </v-col>
      <v-col cols="6" offset="3">
        <v-pagination
          v-if="paging.totalPages > 0"
          v-model="paging.currentPage"
          :length="paging.totalPages"
          :class="!isBusy && hasPaging && !noCount ? '' : 'hidden'"
          @input="handlePageChange"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
/*
  <!-- default -->
  <v-col cols="12">
    `<o-table
      :busy="true" 
      ></o-table>
  </v-col> 

  <!-- without paging -->
  <v-col cols="12">
    <o-table
      :busy="true"
      type="rest"
      resultType="straight"
      :rest="{url:'/rest/accounts?platform_id=1&account_id=2&test=1', payload:null}"
      :headers="[
        { text: 'ID', align:'end', value: 'account_id', type: 'int' },
        { text: 'Name', align: 'start', sortable: false, value: 'account_name',  type: 'text'},          
        { text: 'Active', align:'start', value: 'active', type: 'boolean' },
        { text: 'Notes', align:'start', value: 'notes', type: 'text' },
        { text: 'Timezone', align:'start', value: 'timezone', type: 'text' },
        { value: 'action', type: 'action', 
          actions: [
            {key: 'on-item-edit', label: 'Edit', icon: 'mdi-pencil-box-outline', color: 'success'},
            {key: 'on-item-delete', label: 'Delete', icon: 'mdi-trash-can-outline', color: 'red'}
          ]
        },
      ]"
      @on-item-edit="sendParentChange()"
      @on-item-delete="sendParentChange()"
    ></o-table> 
  </v-col>    

  <!-- paging -->
  <o-table
    :busy="true"
    type="rest"
    :hasPaging="true"
    :rest="{url:'/rest/creatives?platform_id=1&account_id=2&advertiser_id=4', payload:null}"
    :headers="[
      { text: 'ID', align:'end', value: 'account_id', type: 'int' },
      { text: 'Name', align: 'start', sortable: false, value: 'creative_name',  type: 'text'},          
      { text: 'Status', align:'end', value: 'active', type: 'boolean' },
      { text: 'Associated Line Items', align:'end', value: 'associated_line_items', type: 'int' },
      { value: 'action', type: 'action', 
        actions: [
          {key: 'on-item-edit', label: 'Edit', icon: 'mdi-pencil-box-outline', color: 'success'},
          {key: 'on-item-delete', label: 'Delete', icon: 'mdi-trash-can-outline', color: 'red'}
        ]
      },
    ]"
    @on-item-edit="sendParentChange()"
    @on-item-delete="sendParentChange()"
    ></o-table>
*/

import RestApi from "@/services/RestApi";

export default {
  name: "Table",
  mixins: [RestApi],
  props: {
    busy: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "array", // 'array' || 'rest'
    },
    resultType: {
      type: String,
      default: "default", // default = data : { count: 0, results: [] }
      // straight = data : []
    },
    hasPaging: {
      type: Boolean,
      default: false,
    },
    hasSorting: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => {
        return [
          {
            text: "Text",
            align: "start",
            sortable: false,
            value: "fieldText",
            type: "text",
          },
          { text: "Integer", align: "end", value: "fieldInt", type: "int" },
          {
            text: "Decimal",
            align: "end",
            value: "fieldDecimal",
            type: "decimal",
          },
          {
            text: "Percent",
            align: "end",
            value: "fieldPercent",
            type: "percent",
          },
          {
            value: "action",
            type: "action",
            actions: [
              {
                key: "on-item-edit",
                label: "Edit",
                icon: "mdi-pencil-box-outline",
                color: "success",
              },
              {
                key: "on-item-delete",
                label: "Delete",
                icon: "mdi-trash-can-outline",
                color: "danger",
              },
            ],
          },
        ];
      },
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    rest: {
      type: Object,
      default: () => ({
        url: undefined,
        payload: undefined,
      }),
    },
    data: {
      type: Array,
      default: () => {
        return [
          {
            fieldText: "Lorem 1",
            fieldInt: 1234,
            fieldDecimal: 5678.9,
            fieldPercent: 67.89,
          },
          {
            fieldText: "Lorem 2",
            fieldInt: 3210,
            fieldDecimal: 9876.54,
            fieldPercent: 12.34,
          },
        ];
      },
    },
    noCount: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: undefined,
    },
    deletedAt: {
      type: String,
      default: "deleted_at",
    },
    method: {
      type: String,
      default: "GET",
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBusy: true,
      currentData: [],
      currentHeight: "",
      currentFixedHeader: false,
      paging: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
        pageSize: 20,
      },
    };
  },
  watch: {
    busy(newValue) {
      this.isBusy = newValue;
    },
    height: {
      handler: function(newHeight) {
        this.currentHeight = newHeight; //this.currentData.length > 5 ? newHeight : '';
      },
      immediate: true,
    },
    fixedHeader: {
      handler: function(newValue) {
        this.currentFixedHeader = newValue;
      },
      immediate: true,
    },
    data(newValue) {
      if (this.type === "array") {
        this.currentData = newValue;
        this.paging.totalItems = this.currentData.length;
      }
    },
  },
  mounted() {
    setTimeout(async () => {
      if (this.type === "array") {
        this.isBusy = false;
        
        this.currentData = this.data;
        this.paging.totalItems = this.currentData.length;
        this.$emit("set-busy", {busy: false, paging: this.paging});
      } else {
        this.refresh();
      }
    }, 200);
  },
  methods: {
    onEmit(key, item) {
      this.$emit(key, item);
    },
    async refresh() {
      this.isBusy = true;
      this.$emit("set-busy", {busy: true, paging: this.paging});
      setTimeout(async () => {
        let url = this.rest.url;
        let payload = _.cloneDeep(this.rest.payload);
        if (this.hasPaging) {
          if (this.method === "GET") {
            url = `${this.rest.url}&page=${this.paging.currentPage}&page_size=${this.paging.pageSize}`;
          } else {
            if (!this.lazy) {              
              if (payload.params.starts == 0) {
                this.paging.pageSize = payload.params.cnt;
              }
              payload.params.cnt = this.paging.pageSize;
              payload.params.starts = this.paging.currentPage;
            } else {
              if (payload.paging && payload.paging.page_size) {
                this.paging.pageSize = payload.paging.page_size;
              }
              const offset =
                this.paging.currentPage === 1
                  ? 1
                  : (this.paging.currentPage - 1) * this.paging.pageSize +
                    1;
              url = `${this.rest.url}?page=${offset}&page_size=${
                this.paging.pageSize
              }&ts=${new Date().getTime()}`;
            
              //this.paging.currentPage = payload.paging.page - 1;
            }
          }
        }
        if (this.method === "GET") {
          await this.getData(url, this.rest.payload).then((response) => {
            if (this.resultType === "default") {
              this.currentData = response.data.results;
              this.paging.totalItems = parseInt(response.data.count);
              this.paging.totalPages = parseInt(
                this.paging.totalItems / this.paging.pageSize
              );
              if (this.paging.totalItems % this.paging.pageSize > 0)
                this.paging.totalPages = this.paging.totalPages + 1;
            } else {
              this.currentData = response.data;
              this.paging.totalItems = this.currentData.length;
            }
            this.$emit("set-busy", {busy: false, paging: this.paging});
            this.isBusy = false;
          });
        } else {
          if (payload.params && payload.params.starts && payload.params.starts === 1) {
            payload.params.starts = 0;
          }
          await this.postData(url, payload).then((response) => {
            if (this.resultType === "default") {
              this.currentData = response.data.results;
              this.paging.totalItems = parseInt(response.data.count);
              this.paging.totalPages = parseInt(
                this.paging.totalItems / this.paging.pageSize
              );
              if (this.paging.totalItems % this.paging.pageSize > 0)
                this.paging.totalPages = this.paging.totalPages + 1;
            } else {
              this.currentData = response.data;
              this.paging.totalItems = this.currentData.length;
            }
            this.$emit("set-busy", {busy: false, paging: this.paging});
            this.isBusy = false;
          });
        }
        if (!(this.currentData.length > 5 && this.currentFixedHeader)) {
          this.currentFixedHeader = false;
          this.currentHeight = "";
        } else {
          this.currentFixedHeader = this.fixedHeader;
          this.currentHeight = this.height;
        }

        this.$emit("on-refreshed", {
          paging: {
            starts: this.paging.currentPage - 1,
            cnt: this.paging.pageSize,
          },
        });
      }, 200);
    },
    handlePageChange(value) {
      this.paging.currentPage = value;
      this.refresh();
    },
    onClick(event) {
      if (!this.isBusy) {
        this.$emit("on-click", event);
      }
    },
    isDeleted(item) {
      return item[this.deletedAt] && item[this.deletedAt] !=='';      
    },
    getAlignment(align) {
      if (!align) return 'text-left';
      if (align == 'start') return 'text-left';
      if (align == 'end') return 'text-right';
      if (align == 'center') return 'text-center';
    },
    async reset() {
      this.paging = {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
        pageSize: 20,
      };
      await this.refresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.button-icon-skeleton {
  margin: 10px;
  ::v-deep .v-skeleton-loader__text {
    min-height: 40px;
  }
}

.deleted {
  text-decoration: line-through;
  font-style: italic;
  color: red;
}

.hidden {
  display: none;
}

.custom-no-wrap {
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis ;
}

::v-deep .v-data-table__mobile-row {
  max-width: calc(100vw - 80px);
  overflow: hidden;
}

::v-deep .v-data-table-header-mobile {
  max-width: calc(100vw - 80px);
  display: none !important;
} 

</style>
