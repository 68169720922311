export default (to, from, next) => {
  if (
    (localStorage.getItem('userInfo') != null &&
    localStorage.getItem('userInfo').length > 0) &&
    (localStorage.getItem('userKey') != null &&
    localStorage.getItem('userKey').length > 0)
  ) {

    next()
  
  } else {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userKey')

    next('/app/sessions/login')
  }
}
