<template>
  <o-card :class="[background]">
    <v-card-text class="d-flex align-center h-full">
      <div
        class="d-flex flex-grow-1 justify-space-between align-center flex-wrap"
      >
        <v-icon :class="[iconColor, 'text-h3']">
          {{ icon }}
        </v-icon>
        <div>
          <p class="ma-0 text-right white--text mr-1">
            <span
              class="ma-1 text-h6"
              style="font-size:16px;"
            >{{ text }}</span>
            <span 
              v-if="sheetType !== 'icon' && sheetText && sheetText != 0" 
              :title="sheetTitle" 
              :class="['mt-1', innerIconColor]"
              style="border: 1px solid #021729;  background-color: #021729; border-radius: 100px; padding: 5px 5px 7px 5px;"
            >
              <v-icon  
                v-if="sheetText && sheetText > 0"
                right
                small
                :class="['ma-0', innerIconColor]"
              >
                mdi-arrow-up-bold-circle
              </v-icon>
              <v-icon  
                v-if="sheetText && sheetText < 0"
                right
                small
                :class="['ma-0', innerIconColor]"
              >
                mdi-arrow-down-bold-circle
              </v-icon> 
                
              <small style="font-size: 12px;">{{ sheetText ? sheetText: '' }}%</small>
            </span>  
          </p>
          <div class="text-right white--text mt-2 mr-0 mb-1 ">
            <span
              class="text-h5 mt-2 mr-1"
              style="line-height: 16px;"
            >
              {{ value }}
              <small
                class="text-gray ma-0 pa-0 mr-1"
                style="font-size: 12px"
              > | </small>
              <small
                class="text-black ma-0 pa-0 mr-1"
                style="font-size: 10px"
              >{{ sheetTitle }}</small>
            </span>
          </div>
        </div>
      </div>
    </v-card-text>
  </o-card>
</template>
<script>
export default {
  props: {
    background: {
      type: String,
      default: "",
    },
    sheetType: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "text--lighten-2",
    },
    icon: {
      type: String,
      default: "mdi-numeric-9-plus-circle-outline",
    },
    innerIcon: {
      type: String,
      default: "",
    },
    sheetTitle: {
      type: String,
      default: "",
    },
    sheetText: {
      type: String,
      default: "",
    },
    innerIconColor: {
      type: String,
      default: "text--lighten-2",
    },
    text: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
};
</script>
<style scoped>
.sheetCaption {
  font-size: 10px !important;
  font-weight: 400;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  font-family: "Roboto", sans-serif !important
}
</style>