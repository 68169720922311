<template> 
  <div style="margin:0; padding:0; display: inline-block;">
    <v-skeleton-loader 
      v-if="isBusy" 
      :loading="isBusy" 
      type="text" 
      transition="v-scroll-x-transition"
      class="button-icon-skeleton"
    />
    <v-btn 
      v-if="!isBusy && type === 'default'"       
      :color="color"
      @click="onClick(this)"  
    > 
      {{ label }}      
    </v-btn> 

    <v-btn 
      v-if="!isBusy && type === 'icon'"   
      icon   
      :color="color"
      @click="onClick(this)" 
    >       
      <v-icon>{{ icon }}</v-icon>
    </v-btn> 

    <v-btn 
      v-if="!isBusy && type === 'label-icon'" 
      :color="color"
      class="mr-5"
      @click="onClick(this)" 
      small
    > 
      <template v-if="!isMobile">
        <span class="text-capitalize">{{ label }}</span> <v-icon small class="ml-2">{{ icon }}</v-icon>
      </template>      
      <template v-else>
        <v-icon small>{{ icon }}</v-icon>
      </template>
    </v-btn> 
    <v-btn 
      v-if="!isBusy && type === 'right-label-icon'" 
      :color="color"      
      @click="onClick(this)" 
      small
    > 
      <template v-if="!isMobile">
        <v-icon small class="mr-2">{{ icon }}</v-icon><span class="text-capitalize">{{ label }}</span>
      </template>      
      <template v-else>
        <v-icon small>{{ icon }}</v-icon>
      </template>      
      
    </v-btn> 
  </div>
</template>

<script>  
/*
<o-button-icon
  :busy="true"
  @on-click="sendParentChange()"
  ></o-button-icon>
*/

export default { 
    name: "Button",
    props: {
        busy: {
          type: Boolean,
          default: true
        }, 
        label: {
          type: String,
          default: ''
        }, 
        color: {
          type: String,
          default: 'primary'
        }, 
        icon: {
          type: String,
          default: 'mdi-emoticon-outline'
        },
        value: {  
          type: String,
          default: null,
        },
        type: {
          type: [String, Number, Boolean],
          default: 'default' // icon
        }, 
    },  
    data() {      
      return {
        isBusy: true, 
        currentValue: null,
        width: 0
      }
    },
    watch: {
      value(newValue) {
        this.currentValue = newValue;
      }, 
      busy(newValue) { 
        this.isBusy = newValue;
      },  
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
      this.onResize();
      setTimeout(async ()=> {       
        this.isBusy = false;   
      }, 200);
    },
    computed: {
      isMobile() {
        return this.width < 600;
      }
    },
    methods: {
      onResize() {        
        this.width = window.outerWidth;
      },
      onClick(event) {  
        this.$emit('on-click', this.currentValue);        
      }
    }
}
</script>

<style lang="scss" scoped>
  .button-icon-skeleton {
    margin: 10px;
    ::v-deep .v-skeleton-loader__text {
      min-height: 40px;
    }
  }
</style>
