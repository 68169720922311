<template> 
  <div style="padding: 0; margin:0;">
    <v-skeleton-loader
      v-if="isBusy"
      style="padding: 0; margin:0;"  
      type="text" 
      transition="v-scroll-x-transition"
      class="input-skeleton"
    />

    <v-text-field
      v-if="!isBusy && type === 'text'"
      v-model="debouncedModel"
      :rules="rules"
      @blur="updateModel()"    
      :append-icon="hasClear && currentValue !== '' ? 'mdi-close' : ''"
      @click:append="clear()"    
    >
      <template v-slot:label>
        {{ label }}
        <span
          v-if="isRequired"
          class="red--text"
        ><strong> *</strong></span>
      </template> 
    </v-text-field>

    <v-textarea
      v-if="!isBusy && type === 'textarea'"
      v-model="currentValue"
      :label="label"    
      rows="3"  
      @blur="updateModel()"      
    >
      <template v-slot:label>
        {{ label }}
        <span
          v-if="isRequired"
          class="red--text"
        ><strong> *</strong></span>
      </template> 
    </v-textarea>

    <v-text-field
      v-if="!isBusy && (type === 'integer' || type === 'int')"
      v-model="currentValue"      
      @blur="updateModel()"     
      type="number"       
    >
      <template v-slot:label>
        {{ label }}
        <span
          v-if="isRequired"
          class="red--text"
        ><strong> *</strong></span>
      </template> 
    </v-text-field>

    <v-text-field
      v-if="!isBusy && type === 'decimal'"
      type="number"
      v-model="currentValue"
      :rules="rules"      
      @blur="updateModel()"      
    >
      <template v-slot:label>
        {{ label }}
        <span
          v-if="isRequired"
          class="red--text"
        ><strong> *</strong></span>
      </template> 
    </v-text-field>

    <v-text-field
      v-if="!isBusy && type === 'email'"
      v-model="currentValue"
      :rules="rules"      
      @blur="updateModel()"      
    >
      <template v-slot:label>
        {{ label }}
        <span
          v-if="isRequired"
          class="red--text"
        ><strong> *</strong></span>
      </template> 
    </v-text-field>

    <v-text-field
      v-if="!isBusy && type === 'password'"
      v-model="currentValue"
      :rules="rules"      
      @blur="updateModel()"      
    >
      <template v-slot:label>
        {{ label }}
        <span
          v-if="isRequired"
          class="red--text"
        ><strong> *</strong></span>
      </template> 
    </v-text-field>

    <v-text-field
      v-if="!isBusy && type === 'custom'"
      v-model="currentValue"
      :rules="rules"      
      @blur="updateModel()"      
    >
      <template v-slot:label>
        {{ label }}
        <span
          v-if="isRequired"
          class="red--text"
        ><strong> *</strong></span>
      </template> 
    </v-text-field>

    <v-text-field
      v-if="!isBusy && type === 'with-button'"
      v-model="currentValue"
      :rules="rules"      
      :append-icon="buttonIcon"  
      @blur="updateModel()"
      @click:append="onIconClick()"
    >
      <template v-slot:label>
        {{ label }}
        <span
          v-if="isRequired"
          class="red--text"
        ><strong> *</strong></span>
      </template> 
    </v-text-field>
  </div>
</template>

<script>  
/*
<o-input
  :busy="true"
  v-model="model"
  label="Test Label"
  @on-blur="sendParentChange()"
  ></o-input>
*/

import _ from 'lodash'

export default { 
    name: "Input",
    props: {
        busy: {
          type: Boolean,
          default: true
        }, 
        label: {
          type: String,
          default: 'Label'
        }, 
        type: {
          type: String,
          default: 'text' // text, textarea, integer, decimal, email, custom
        }, 
        value: {
          type: [String, Number, Boolean],
          default: null,
        },
        mask: {
          type: String,
          default: ''
        },
        hasIcon: {
          type: Boolean,
          default: false
        },
        buttonIcon: {
          type: String,
          default: 'mdi-content-save'
        },
        action: {
          type: Object,
          default: () => ({
                icon: undefined,
                action: undefined,
                color: ''
            })
        },
        rows: {
          type: Number,
          default: 0
        },
        decimalPlaces: {
          type: Number,
          default: 0
        },
        isRequired: {
          type: Boolean,
          default: false
        },
        hasClear: {
          type: Boolean,
          default: false
        },
        rules: {
          type: Array,
          default: () => ([])
        }, 
        timeout: {
          type:Number,
          default: 200
        }
    },  
    data() {      
      return {
        isBusy: true, 
        currentValue: '',
      }
    },
    computed: {
      updateValueDebounced() {
        return _.debounce(this.updateModel, this.timeout);
      },
      debouncedModel: {
        get() { return this.currentValue; },
        set(value) { this.currentValue = value; this.updateValueDebounced(value); }
      },
    },
    watch: {
      value(newValue) {        
        this.currentValue = newValue;
      }, 
      busy(newValue) {
        this.isBusy = newValue;
      },   
    },
    mounted() {
      setTimeout(async ()=> {       
        this.currentValue = this.value;
        this.isBusy = false;           
      }, 200);
    },
    methods: {
      onClick(event) {        
        this.$emit('on-click', event)
      },
      onIconClick(event) { 
        this.$emit('input', this.currentValue)
        this.$emit('on-icon-click', event)
      },
      updateModel() {
        this.$emit('input', this.currentValue) 
        this.$emit('on-value-change', event)
      },
      clear() {
        this.currentValue = '';
        this.$emit('input', this.currentValue)
        this.$emit('on-value-change', event)
      }
    }
}
</script>

<style lang="scss" scoped>
  .input-skeleton {
    margin: 10px;
    ::v-deep .v-skeleton-loader__text {
      min-height: 66px;
    }
  }
</style>
