/*
 * ============================
 * File: main.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import "./plugins";

import octaviaKit from "@/plugins/octavia.kit";

// import VueApexCharts from 'vue-apexcharts';
// mock
import "./fake-db/index.js";

// Vue.component('apexchart', VueApexCharts);

Vue.use(octaviaKit);
Vue.config.productionTip = false;

/*if (process.env.NODE_ENV === "development") {
  makeServer();
}*/

import {createClient, withProvider} from 'vue-gql';
import {SubscriptionClient} from 'subscriptions-transport-ws';

const subscriptionClient = new SubscriptionClient(process.env.VUE_APP_GRAPHQL_WS_URL, {});

const client = createClient({
    url: process.env.VUE_APP_GQL_URL,
    subscriptionForwarder: op => subscriptionClient.request(op)
});

const AppWithClient = withProvider(App, client);

new Vue({
  store,
  router,
  vuetify,
  render: h => h(AppWithClient)
}).$mount("#app");
