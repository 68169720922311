<template>  
  <v-btn 
    :loading="isBusy" 
    icon
    :color="color"
    @click="onClick(this)" 
  >
    <v-icon small>
      {{ icon }}
    </v-icon>
  </v-btn> 
</template>

<script>  
/*
<o-button-icon
  :busy="true"
  @on-click="sendParentChange()"
  ></o-button-icon>
*/

export default { 
    name: "LineChart",
    props: {
        busy: {
          type: Boolean,
          default: true
        }, 
        label: {
          type: String,
          default: 'Label'
        }, 
        color: {
          type: String,
          default: 'primary'
        }, 
        icon: {
          type: String,
          default: 'mdi-emoticon-outline'
        },
        value: {
          type: [String, Number, Boolean],
          default: null,
        },      
    },  
    data() {      
      return {
        isBusy: true, 
        currentValue: '',
      }
    },
    watch: {
      value(newValue) {
        this.currentValue = newValue;
      }, 
      busy(newValue) {
        this.isBusy = newValue;
      },   
    },
    mounted() {
      setTimeout(async ()=> {       
        this.isBusy = false;   
      }, 200);
    },
    methods: {
      onClick(event) {
        this.$emit('on-click', event)
      }
    }
}
</script>

<style lang="scss" scoped>
  .button-icon-skeleton {
    margin: 10px;
    ::v-deep .v-skeleton-loader__text {
      min-height: 40px;
    }
  }
</style>
