<template> 
  <div class="o-container"> 
    <v-snackbar
      v-model="show"
      :timeout="timeout" 
      dense
      :color="color"
      :multi-line="true"
      right
      rounded="pill"
      bottom
    >
      {{ displayMessage }}
    </v-snackbar>   
    <v-row>
      <v-col
        v-if="isTest"
        cols="12"
      >      
        <v-btn @click="snackBar()">
          Snackbar
        </v-btn>
      </v-col>
      <v-col>
        <slot />
      </v-col>
    </v-row>
    <v-dialog 
      v-model="openDialog"
      max-width="50vw" 
      transition="dialog-bottom-transition"
    >
      <v-card> 
        <v-card-title class="text-h5 red">
          {{ displayTitle }}
        </v-card-title>

        <!-- eslint-disable vue/no-v-html -->
        <v-card-text
          class="pa-5"          
          v-html="displayMessage"
        />
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="primary"
            @click="dialogResponse(true)"
          >
            Yes
          </v-btn>
          <v-btn @click="dialogResponse(false)">
            No
          </v-btn>
        </v-card-actions>
      </v-card>    
    </v-dialog>
  </div>
</template>

<script>  
/*
<o-container ref="container">

</o-container>
*/

import RestApi from '@/services/RestApi'

export default { 
    name: "Container", 
    props: {
      isTest: {
        type: Boolean,
        default: false
      }, 
    },
    data() {      
      return {
        timeout: 1000,
        show: false,
        color: 'primary', 

        displayTitle: 'Sample Title',
        displayMessage: 'Sample Message',

        openDialog: false,  
        resolveDialog: null
      }
    },
    watch: {
      value(newValue) {
        this.currentValue = newValue;
      }, 
      busy(newValue) {
        this.isBusy = newValue;
      },   
    },
    mounted() {
      setTimeout(async ()=> {       
        this.isBusy = false;   
      }, 200);
    },
    methods: {
      snackBar(message, color, timeout) {   
        setTimeout(async ()=> {       
          this.displayMessage = message;
          this.color = color;
          this.timeout = timeout;
          this.show = true;  
        }, 200);     
      },
      confirm(title, message, color) {
        this.displayTitle = title;
        this.displayMessage = message;
        this.color = color;
        this.openDialog = true; 
        return new Promise((resolve, reject) => {
            this.resolveDialog = resolve;
        });
      },
      dialogResponse(response) {
        this.openDialog = false;
        this.resolveDialog(response)
      }
    }
}
</script>

<style lang="scss" scoped> 
  /*b.o-container {
    ackground-color: blue;
    height: calc(100vh - 160px);
  }*/
</style>
