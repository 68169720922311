<template>
  <v-row class="pa-0 ma-0">
    <v-col cols="12" sm="12" md="12" class="pa-0 ma-0">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="ml-4"
            v-model="dateRangeText"
            label="Date Range"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="dates" no-title scrollable range>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(dates); onChange(null)">OK</v-btn>
        </v-date-picker>
      </v-menu>
    </v-col> 
  </v-row>
</template>

<script>  
/*
<o-date-range
  :busy="true"
  v-model="model"
  label="Test Label"
  @on-blur="sendParentChange()"
  ></o-date-range>
*/

export default { 
    name: "Date",
    props: {
        busy: {
          type: Boolean,
          default: true
        }, 
        label: {
          type: String,
          default: 'Label'
        },  
        value: {  
          type: Array,
          default: () => ([])
        },   
        rules: {
          type: Array,
          default: () => ([])
        }      
    },  
    data() {      
      return {
        isBusy: true, 
        dates: ['2019-09-10', '2019-09-20'],
        menu: false
      }
    },
    computed: {
      dateRangeText () {
        console.log(this.dates[1])
        if (this.dates[1] == 'null') {
          return this.dates.join('')
        } else {
          return this.dates.join(' to ')
        }
      },
    },
    watch: {  
      busy(newValue) {
        this.isBusy = newValue;
      },   
    },
    mounted() {
      setTimeout(async ()=> {       
        this.isBusy = false;  
      }, 200);
    },
    methods: {
      onChange(event) {
        if (new Date(this.dates[0]) > new Date(this.dates[1])) {
          this.dates = [this.dates[1], this.dates[0]]
          this.$refs.menu.save(this.dates);
        }
        this.$emit('input', this.dates) 
        this.$emit('on-change', event)
      }
    }
}
</script>

<style lang="scss" scoped>
   
</style>
