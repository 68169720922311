<template> 
  <div style="padding: 0; margin:0;">
    <v-skeleton-loader 
      v-if="isBusy" 
      :loading="isBusy" 
      type="text" 
      transition="v-scroll-x-transition"
      class="button-icon-skeleton"
    />
    <v-text-field
      v-if="textbox"
      v-model="currentValue"
      :label="label"              
    />
  </div>
</template>

<script>  
/*
<o-text-box
  :busy="true"
  v-model="model"
  label="Test Label"
  @on-blur="sendParentChange()"
  ></o-text-box>
*/

export default { 
    name: "RadioGroup",
    props: {
        busy: {
          type: Boolean,
          default: true
        }, 
        label: {
          type: String,
          default: 'Label'
        }, 
        type: {
          type: String,
          default: 'textbox' // combo, textbox, textarea, checkbox/switch, radio
        }, 
        value: {
          type: [String, Number, Boolean],
          default: null,
        },      
    },  
    data() {      
      return {
        isBusy: true, 
        currentValue: '',
      }
    },
    watch: {
      value(newValue) {
        this.currentValue = newValue;
      }, 
      busy(newValue) {
        this.isBusy = newValue;
      },   
    },
    mounted() {
      setTimeout(async ()=> {       
        this.isBusy = false;   
      }, 200);
    },
    methods: {
      onClick(event) {
        this.$emit('on-click', event)
      }
    }
}
</script>

<style lang="scss" scoped>
  .button-icon-skeleton {
    margin: 10px;
    ::v-deep .v-skeleton-loader__text {
      min-height: 40px;
    }
  }
</style>
