<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.getThemeMode.appTitle,
      // all titles will be injected into this template
      titleTemplate: `%s | Dashboard `
    };
  },
  computed: {
      ...mapGetters(['getThemeMode'])
  },
  mounted() {
    this.$vuetify.theme.isDark = true;
  },
  created() {
    const mq = window.matchMedia("(prefers-color-scheme: dark)");
    mq.addEventListener("change", e => {
      this.$vuetify.theme.dark = e.matches;
    });
    this.getRouterParams();
  },
  methods: {
    getRouterParams() {
      // console.log(this.$router.currentRoute.query);
    }
  }
};
</script>