<template>
  <v-dialog
    ref="dialog"
    v-model="visibility"
      
    :width="getWidth(true)"
    :content-class="getWidth(false)"
    transition="dialog-bottom-transition"
  >
    <template
      v-if="useButton"
      v-slot:activator="{ on, attrs }"
    >
      <v-btn
        class="ma-2"
        
        color="primary"
        v-bind="attrs"
        v-on="on"        
      >
        <v-icon
          small
          class="mr-2"
        >
          {{ buttonIcon }}
        </v-icon>
        <span style="text-transform: uppercase;">{{ title }}</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title
        class="active text-h5 lighten-2"
        style="text-transform: uppercase;"
      >
        {{ title }}
      </v-card-title>
      <v-divider />
      <v-card
        flat
        fill-height
      >
        <v-card-text class="drawer-container">
          <slot />  
        </v-card-text>
        <v-divider />
        <v-card-actions
          
          class="sticky_btn lighten-2"
        >
          <v-spacer /> 
          <v-btn
            v-for="(action, j) in actions"
            :key="j"
            top
            :color="action.color" 
            @click="onEmit(action.key)"
            small
          >
            {{ action.label }}
            <v-icon
              v-if="action.icon !== ''"
              class="ml-2"
              small
            >
              {{ action.icon }}
            </v-icon>
          </v-btn> 
          
          <v-btn
            class="ma-2 mr-10"
            style="color:white"
            color="red"
            @click="close"
            small
          >
            Exit <v-icon
              small
              class="ml-2"
            >
              mdi-close-box
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card> 
    </v-card>
  </v-dialog>
</template>

<script>  
/*
<o-drawer>

</o-drawer>
*/
export default { 
    name: "Drawer", 
    props: {      
      visible: {
        type: Boolean,
        default: false
      }, 
      icon: {
        type: String,
        default: ''
      }, 
      title: {
        type: String,
        default: '',
      }, 
      actions: {
        type: Array,
        default: () => {
          return [
            {key: 'on-item-save-and-continue', label: 'Save & Continue', icon: 'mdi-content-save-edit-outline', color: ''},
            {key: 'on-item-save', label: 'Save', icon: 'mdi-content-save', color: 'success'}
          ]
        }
      },
      width: {
        type: String,
        default: '100vw'
      },
      autoClose: {
        type: Boolean,
        default: false,
      },
      useButton: {
        type: Boolean,
        default: false,
      },
      buttonIcon: {
        type: String,
        default: 'mdi-plus',
      }
    },
    data() {      
      return {         
        visibility: false,   
      }
    },
    watch: {
      visible(newValue) {
        this.visibility = newValue; 
        
      },  
      visibility(newValue) {
        if (!newValue) this.close();
      }
    },
    mounted() {
      setTimeout(async ()=> {       
        
      }, 200);
    },
    methods: {
      onEmit(key) { 
        if(this.autoClose) {          
          this.visibility = false;
        }
        this.$emit(key, this.value);
      }, 
      close() {
        this.visibility = false;
        this.onEmit('on-close')
      },
      getWidth(type) {
        if (window.innerWidth < 600) return '';
        if (type) {
          return this.width;
        }
        else {
          
          const outValue = `dw-${this.width}`;
          return outValue;
        }
      }
    }
}
</script>

<style lang="scss" scoped> 
  .drawer-container {
    min-height: calc(100vh - 132px);
    max-height: calc(100vh - 132px);
    overflow-y: auto;
    padding-right: 30px;
  }

  ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100vh !important;
    max-height: 100vh !important;
    margin: 0px !important;
    border-radius: 0px !important;    
    overflow-y: hidden;
  }
  ::v-deep .v-card__title {
    border-radius: 0px !important;
  }
  ::v-deep .v-card__actions {
    border-radius: 0px !important;
  } 

  ::v-deep .dw-25vw {
    width: 25vw;
    position: absolute;
    left: 75vw;
  }
  ::v-deep .dw-50vw {
    width: 50vw;
    position: absolute;
    left: 50vw;
  }
  ::v-deep .dw-75vw {
    width: 75vw;
    position: absolute;
    left: 25vw;
  }  

</style>

