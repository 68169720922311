<template> 
  <div class="code-container">
    <v-btn
      class="ma-1"
      x-small
      :color="isRaw? '': 'info'"
      @click="isRaw = false"
    >
      Formatted
    </v-btn>
    <v-btn
      class="ma-1"
      x-small
      :color="isRaw? 'info': ''"
      @click="isRaw = true"
    >
      Raw
    </v-btn>
    <code-highlight v-if="!isRaw">
      <pre
        v-if="type === 'json'"
        class="language-json"
      >{{ getFormattedText() }}</pre>
      <pre
        v-if="type === 'xml'"
        class="language-xml"
      >{{ getFormattedText() }}</pre>
    </code-highlight>
    <pre
      v-if="isRaw"
      class="raw-font"
    >
{{ value }}
    </pre>
  </div>
</template>
  
<script>  
/*
<o-code      
  v-model="code1"
  label="Test Label"
  type="xml"
  :raw="false"
  ></o-code>
<o-code      
  v-model="code2"
  label="Test Label"
  type="json"
  :raw="false"
  ></o-code>
*/

import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";  
import XmlBeautify from 'xml-beautify/dist/XmlBeautify';


export default { 
    name: "Code",
    components: {CodeHighlight},
    props: {
        busy: {
          type: Boolean,
          default: false
        }, 
        label: {
          type: String,
          default: 'Label'
        },
        type: {
          type: String,
          default: 'json'
        },
        value: {
          type: [String, Number, Boolean],
          default: null
        },
        raw: {
          type: Boolean,
          default: false,
        }
    },  
    data() {      
      return {
        isBusy: true, 
        isRaw: false,
      }
    },
    watch: { 
      busy(newValue) {
        this.isBusy = newValue;
      },
      raw(newValue) {
        this.isRaw = newValue;
      },
    },
    mounted() { 
    },
    methods: {      
      getFormattedText() {
        if (this.type == 'json') {
          let output = this.value 
          if (typeof this.value === 'string') {
            try {
              output = JSON.parse(this.value)
              return JSON.stringify(output, null, 4)
            } catch (e) {
              return this.value
            }
          }
        } else if (this.type == 'xml') {
          return new XmlBeautify().beautify(this.value)
        }
        return this.value;      
      }, 
    }
}
</script>

<style scoped> 
::v-deep .language-javascript {
  background-color: unset !important;
}
.raw-font {
  font-family: Hack, Consolas, Menlo, Monaco, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", "Courier New", Courier, monospace;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
