import axios from "axios";
import moment from "moment";
import _ from "lodash";
import pluralize from "pluralize";

const asyncLocalStorage = {
  setItem: async function (key, value) {
      await null;
      return localStorage.setItem(key, value);
  },
  getItem: async function (key) {
      await null;
      return localStorage.getItem(key);
  }
};

const apiUrl = process.env.VUE_APP_API_URL;
const appId = "octilliontv";

axios.interceptors.request.use(async (config) => {
  const apiKey = await asyncLocalStorage.getItem("userKey")

  if (apiKey) {
      config.headers["x-app-id"] = appId
      config.headers["x-api-token"] = apiKey
  }

  return config
}, (error) => {
  return Promise.reject(error)
})

export default {
  state: {
    activityLogs: [],
    latestActivityLogCount: 0
  },
  getters: {
    activityLogs: state => state.activityLogs,
    latestActivityLogCount: state => state.latestActivityLogCount,
  },
  mutations: {
    setActivityLog(state, data) {
      state.activityLogs = data;
    },
    setLatestActivityLogCount(state, count) {
      state.latestActivityLogCount = count;
    },
    clearActivityLog(state) {
      state.activityLogs = [];
      state.latestActivityLogCount = 0;
    }
  },
  actions: {
    getActivityLogs: async ({ commit }) => {
      commit("clearActivityLog");

      const userInfo = JSON.parse(await asyncLocalStorage.getItem("userInfo"))

      let users = []
      let latest_count = 0

      const filter = `?platform_id=1&account_id=${userInfo.account_id}&limit=10`;
      const parent = this;
      axios({
        url: `${apiUrl}/rest/users${filter}`,
        method: "GET",
      })
      .then((response) => {
        
          users = response.data

          axios({
            url: `${apiUrl}/rest/intranet/execute`,
            method: "POST",
            data: {
              topic: '',
              name: 'check_activity_logs',
              params: {
                acc_id: 0,
                usr_id: 0,
                obj_type: '',
                obj_id: 0,
                cnt: 30,
                starts: 0,
              }
            }, 
          })
          .then((response1) => {
            const activity_logs = _.map(response1.data.results, (o) => {
              let iconName = "";
              let iconColor = "";
              let iconColorText = "";
    
              switch (o.action) {
                default:
                case "CREATE":
                  iconName = "mdi-pencil-outline";
                  iconColor = "bg-blue-200";
                  iconColorText = "info--text";
                  break;
                case "UPDATE":
                  iconName = "mdi-update";
                  iconColor = "bg-blue-200";
                  iconColorText = "info--text";
                  break;
                case "DELETE":
                  iconName = "mdi-minus-circle";
                  iconColor = "bg-orange-200";
                  iconColorText = "warning--text";
                  break;
              }
     
              const _date = moment(o.activity_date)
              const _mins = moment().diff(_date, 'minutes')

              if (_mins <= 1) latest_count = latest_count + 1
              
              return {
                iconName: iconName,
                iconColor: iconColor,
                iconColorText: iconColorText,
                title: o.user_full_name,
                object_action: o.action_name == 'INSERT' ? 'CREATE' : o.action_name, // o.action,
                object_type: pluralize(o.object_type).toUpperCase(),
                time: moment(o.activity_date).fromNow(),
                activity_id: o.id,
              };
            });
    
            commit("setActivityLog", activity_logs);
            commit("setLatestActivityLogCount", latest_count);
          })
          .catch((err1) => { 
            if (err1) {              
              if (err1 && err1.response && [401,403].includes(err.response.status)) {
                  // redirect to login page
                  localStorage.removeItem('userInfo')
                  localStorage.removeItem('userKey')
                  localStorage.removeItem('ttl')
                  parent.$router.push(`/app/sessions/login`)                  
              }
            }
          });
      }).catch((err) => {
        if (err) {              
          if (err && err.response && [401,403].includes(err.response.status)) {
              // redirect to login page
              localStorage.removeItem('userInfo')
              localStorage.removeItem('userKey')
              localStorage.removeItem('ttl')
              parent.$router.push(`/app/sessions/login`)                  
          }
        }
      });
      ;
    }
  }
};
