<template> 
  <div style="padding: 0; margin:0; max-height: 28px;">
    <v-skeleton-loader
      v-if="isBusy"
      style="padding: 0; margin:0;"  
      type="text" 
      transition="v-scroll-x-transition"
      class="checkbox-skeleton"
    />
    <v-checkbox
      v-if="!isBusy"
      v-model="currentValue"  
      style="margin: 0 0 0 20px; "      
      :rules="rules"
      :label="label"
      dense
      @change="onChange()"
    />
  </div>
</template>

<script>  
/*
<o-check-box
  :busy="true"
  v-model="model"
  label="Test Label"
  @on-blur="sendParentChange()"
  ></o-check-box>
*/

export default { 
    name: "CheckBox",
    props: {
        busy: {
          type: Boolean,
          default: true
        }, 
        label: {
          type: String,
          default: 'Label'
        },  
        value: {  
          type: Boolean,
          default: false,
        },   
        rules: {
          type: Array,
          default: () => ([])
        }      
    },  
    data() {      
      return {
        isBusy: true, 
        currentValue: false,
      }
    },
    watch: {
      value(newValue) {
        this.currentValue = newValue;
      }, 
      busy(newValue) {
        this.isBusy = newValue;
      },   
    },
    mounted() {
      setTimeout(async ()=> {       
        this.isBusy = false;  
        this.currentValue = this.value;
      }, 200);
    },
    methods: {
      onChange(event) {
        this.$emit('input', this.currentValue) 
        this.$emit('on-change', event)
      }
    }
}
</script>

<style lang="scss" scoped>
  .checkbox-skeleton {
    margin: 10px;
    ::v-deep .v-skeleton-loader__text {
      min-height: 46px;
    }
  }
</style>
