<template> 
  <div style="padding: 0; margin:0;">
    <v-skeleton-loader
      v-if="isBusy"
      style="padding: 0; margin:0;"  
      type="text" 
      transition="v-scroll-x-transition"
      class="dropdown-skeleton"
    />
    <v-autocomplete
      v-if="!isBusy"
      ref="dropdown"  
      v-model="currentValue" 
      :items="data"
      :item-text="(item) => setLabel(item)"
      :item-value="valueField"     
      :rules="rules"     
      :multiple="multiple" 
      @change="(value) => onChange(value)"
    >
      <template v-slot:label>
        {{ label }}
        <span
          v-if="isRequired"
          class="red--text"
        ><strong> *</strong></span>
      </template>
    </v-autocomplete> 
  </div>
</template>

<script>  
/*
<o-combo-box
  :busy="true"       
  label="DSP Platform" 
  :items="[
        { id: '1', name: 'octillion' },
        { id: '2', name: 'beeswax' }, 
  ]"         
  v-model="advertiser_id"
  @on-change="sendParentChange" 
  ></o-combo-box>

<o-combo-box
  :busy="true"
  displayField="advertiser_name"   
  label="Advertiser" 
  :rest="{url:'/rest/advertisers?platform_id=1&account_id=2', payload:null}"
  type="rest"  
  valueField="advertiser_id"
  v-model="advertiser_id"
  @on-change="sendParentChange" 
  ></o-combo-box>
*/

import RestApi from '@/services/RestApi'

export default {
    name: "ComboBox",
    mixins: [RestApi],
    props: {
        busy: {
          type: Boolean,
          default: true
        },
        type: {
          type: String,
          default: 'array' // 'array' || 'rest'
        },        
        label: {
          type: String,
          default: 'Label'
        },
        items: {
          type: Array,
          default: () => {
            return [
              { id: '1', name: 'one' },
              { id: '2', name: 'two' },
              { id: '3', name: 'three' }];
          }
        },
        rest: {
          type: Object,
          default: () => ({
                url: undefined,
                payload: undefined,
            })
        },
        resultType: {
          type: String,
          default: 'default' // default = data : { count: 0, results: [] }
                             // straight = data : []
        },
        displayField: {
          type: String,
          default: 'name'
        },
        valueField: {
          type: [String, Number],
          default: 'id'
        },
        value: {
          type: [String, Number, Boolean, Array],
          default: null,
        },
        isRequired: {
          type: Boolean,
          default: false
        },
        rules: {
          type: Array,
          default:  () => ([])
        },
        multiple: {
          type: Boolean,
          default: false
        },
        hasId: {
          type:Boolean,
          default: true
        } 
    },
    data() {
      return {
        isBusy: true,
        data: [],
        currentValue: null,
        searchQ: null,
        loading: true,
        rulesData: [], 
      }
    },
    watch: {
      value(newValue) {
        this.currentValue = newValue;
      }, 
      items(newValue) {
        this.data = newValue;
      },
      busy(newValue) { 
        this.isBusy = newValue; 
      },  
      rest(newValue) {
        if (this.type === 'rest') {
          this.displayData();
        } else {
          this.data = this.items;   
          this.isBusy = false;
        }
      }, 
    },
    mounted() {
      this.isBusy = true;       
      setTimeout(async ()=> {       
        this.currentValue = this.value;      
        if (this.type === 'rest') {
           this.displayData();
        } else { 
          this.data = this.items;   
          this.isBusy = false;
        }
      }, 200);            
    },  
    methods: {
      setLabel(item) {
        if ((item[this.valueField] && item[this.valueField] != '' && this.hasId)
          && item[this.valueField] != -1
        )
        {
          return  `${item[this.displayField]} (${item[this.valueField]})`;
        } 
        return `${item[this.displayField]}`;
      },
      onChange(value) {   
        this.currentValue = value;    
        this.$emit('input', this.currentValue)
        this.$emit('on-change', value);           
      }, 
      displayData() { 
        setTimeout(async () => {          
          this.getData(this.rest.url, this.rest.payload).then((response) => {
            if (this.resultType === 'default') {
             this.data = response.data.results;
            } else {
              this.data = response.data; 
            }               
            this.isBusy = false; 
            this.currentValue = this.value; 
            return Promise.resolve();
          });
        }, 200);
      }
    },
}
</script>

<style lang="scss" scoped>
  .dropdown-skeleton {
    margin: 10px;
    ::v-deep .v-skeleton-loader__text {
      min-height: 66px;
    }
  }
</style>
