<template> 
  <div style="padding: 0; margin:0;">
    <v-skeleton-loader 
      v-if="isBusy" 
      :loading="isBusy" 
      type="text" 
      transition="v-scroll-x-transition"
      class="button-icon-skeleton"
    />
    {{label}}
     <v-text-field
      v-if="!isBusy"
      v-model="textValue"      
      append-icon="mdi-plus"
      @click:append="add()"    
    >
        {{ label }}
    </v-text-field>
    <v-list style="padding: 0; margin:0;">
      
      <template v-for="(item, index) in currentValue">
        <v-list-item :key="item"> 
          <v-list-item-content>
            {{index + 1}}. {{item}}
          </v-list-item-content>

          <v-list-item-action>
            <v-icon 
              color="red"
              @click="remove(item)"
            >
              mdi-delete
            </v-icon> 
          </v-list-item-action> 
        </v-list-item>

        <v-divider
          v-if="index < currentValue.length - 1"
          :key="index"
        ></v-divider>
      </template> 
    </v-list>
  </div>
</template>

<script>   

export default { 
    name: "ListString",
    props: {
        busy: {
          type: Boolean,
          default: true
        }, 
        label: {
          type: String,
          default: 'Label'
        },  
        value: {
          type: [Array],
          default: null,
        },      
    },  
    data() {      
      return {
        isBusy: true, 
        currentValue: [],
        textValue: '',
      }
    },
    watch: {
      value(newValue) {
        this.currentValue = newValue;
        console.log(newValue)
      }, 
      busy(newValue) {
        this.isBusy = newValue;
      },   
    },
    mounted() {
      this.currentValue = this.value;
      this.isBusy = this.busy;
      setTimeout(async ()=> {       
        this.isBusy = false;   
      }, 200);
    },
    methods: {
      add() {
        if (this.textValue !== '') {
          this.currentValue.push(this.textValue);
          this.textValue = '';
          this.$emit('input', this.currentValue)
        }
      },
      remove(item) {
        this.currentValue = this.currentValue.filter((d) => d !== item)
        this.$emit('input', this.currentValue)
      },
      onClick(event) {
        this.$emit('on-click', event)
      }
    }
}
</script>

<style lang="scss" scoped>
  .button-icon-skeleton {
    margin: 10px;
    ::v-deep .v-skeleton-loader__text {
      min-height: 40px;
    }
  }
</style>
