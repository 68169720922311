/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import Router from "vue-router";
import store from "@/store";
import authenticate from "@/auth/authenticate";

Vue.component("apexchart", VueApexCharts);

import Card from "@/components/Card";
import Code from "@/components/Code";
import BarChart from "@/components/BarChart";
import Button from "@/components/Button";
import ButtonGroup from "@/components/ButtonGroup";
import CheckBox from "@/components/CheckBox";
import ComboBox from "@/components/ComboBox";
import Container from "@/components/Container";
import DatePicker from "@/components/DatePicker";
import DateRangePicker from "@/components/DateRangePicker";
import Drawer from "@/components/Drawer";
import Input from "@/components/Input";
import LineChart from "@/components/LineChart";
import PieChart from "@/components/PieChart";
import RadioGroup from "@/components/RadioGroup";
import Switches from "@/components/Switches";
import Table from "@/components/Table";
import Widget from "@/components/Widget";
import Subscribe from "@/components/Subscribe";
import StringList from "@/components/StringList";

Vue.component("o-card", Card);
Vue.component("o-code", Code);
Vue.component("o-bar-chart", BarChart);
Vue.component("o-button", Button);
Vue.component("o-button-group", ButtonGroup);
Vue.component("o-check-box", CheckBox);
Vue.component("o-combo-box", ComboBox);
Vue.component("o-container", Container);
Vue.component("o-date", DatePicker);
Vue.component("o-date-range", DateRangePicker);
Vue.component("o-input", Input);
Vue.component("o-line-chart", LineChart);
Vue.component("o-pie-chart", PieChart);
Vue.component("o-radio-group", RadioGroup);
Vue.component("o-switches", Switches);
Vue.component("o-table", Table);
Vue.component("o-drawer", Drawer);
Vue.component("o-widget", Widget);
Vue.component("o-subscribe", Subscribe);
Vue.component("o-string-list", StringList); 

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "main",
      component: () => import("@/views/wrapper/Index"),
      beforeEnter: authenticate,
      redirect: "/app/dashboard/performances",

      children: [
        {
          path: "/app/dashboard",
          component: () => import("@/views/wrapper/dashboard/Index"),

          children: [
            {
              path: "performances",
              name: "performances",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "Performances" }],
              },
              component: () => import("@/views/wrapper/dashboard/Performance"),
            },
            {
              path: "service-status",
              name: "service_status",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "Services" }],
              },
              component: () =>
                import("@/views/wrapper/dashboard/ServiceStatus"),
            },
            {
              path: "services",
              name: "services",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "Services" }],
              },
              component: () =>
                import("@/views/wrapper/dashboard/ServiceStatus"),
            },
            {
              path: "database",
              name: "dashboard-database",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "Database" }],
              },
              component: () =>
                import("@/views/wrapper/dashboard/Database"),
            },
            {
              path: "compare-stats",
              name: "dashboard-compare-stats",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "Compare Stats" }],
              },
              component: () =>
                import("@/views/wrapper/dashboard/CompareStat"),
            },
          ],
        }, 
        {
          path: "/app/manage",
          component: () => import("@/views/wrapper/manage/Index"),
          children: [
            {
              path: "platform-fee",
              name: "manage_platform_fee",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Platform Fees" }],
              },
              component: () => import("@/views/wrapper/manage/PlatformFee"),
            },
            {
              path: "accounts",
              name: "accounts",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Accounts" }],
              },
              component: () => import("@/views/wrapper/manage/Account"),
            },
            {
              path: "roles",
              name: "manage_role",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Roles" }],
              },
              component: () => import("@/views/wrapper/manage/Role"),
            },
            {
              path: "users",
              name: "manage_user",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Users" }],
              },
              component: () => import("@/views/wrapper/manage/User"),
            },
            {
              path: "vendors",
              name: "manage_vendor",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Vendors" }],
              },
              component: () => import("@/views/wrapper/manage/Vendor"),
            },
            {
              path: "advertisers",
              name: "manage_advertiser",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Advertiser" }],
              },
              component: () => import("@/views/wrapper/manage/Advertiser"),
            },
            {
              path: "activity-logs",
              name: "manage_activity_log",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Activity Logs" }],
              },
              component: () => import("@/views/wrapper/manage/ActivityLog"),
            },
            {
              path: "segment-mapping",
              name: "manage_segment_mapping",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Segments Mapping" }],
              },
              component: () => import("@/views/wrapper/manage/SegmentMap"),
            },
            {
              path: "app-lookups",
              name: "manage_apps_lookup",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Apps Lookup" }],
              },
              component: () => import("@/views/wrapper/manage/AppLookup"),
            },
            {
              path: "agencies",
              name: "manage_agency",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Agencies" }],
              },
              component: () => import("@/views/wrapper/manage/Agency"),
            },
            {
              path: "creative-segments",
              name: "manage_creative_segment",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Creative Segments" }],
              },
              component: () => import("@/views/wrapper/manage/CreativeSegment"),
            },
            {
              path: "custom-lists",
              name: "manage_custom_lists",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Custom Lists" }],
              },
              component: () => import("@/views/wrapper/manage/CustomList"),
            },
            {
              path: "deals",
              name: "manage_deals",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Deals" }],
              },
              component: () => import("@/views/wrapper/manage/Deal"),
            },
            {
              path: "lists",
              name: "manage_lists",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Lists" }],
              },
              component: () => import("@/views/wrapper/manage/List"),
            },
            {
              path: "manufacturers",
              name: "manage_manufacturers",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Manufacturers" }],
              },
              component: () => import("@/views/wrapper/manage/Manufacturer"),
            },
            {
              path: "platform-fees",
              name: "manage_platform_fees",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Platform Fees" }],
              },
              component: () => import("@/views/wrapper/manage/PlatformFee"),
            },
            {
              path: "web-segments",
              name: "manage_web_segments",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Web Segments" }],
              },
              component: () => import("@/views/wrapper/manage/WebSegment"),
            },
            {
              path: "third-parties",
              name: "manage_third_parties",
              meta: {
                breadcrumb: [{ name: "Manage" }, { name: "Third Parties" }],
              },
              component: () => import("@/views/wrapper/manage/ThirdParty"),
            },
          ],
        },
        {
          path: "/app/api",
          component: () => import("@/views/wrapper/api/Index"),

          children: [
            {
              path: "keys",
              name: "api_key",
              meta: {
                breadcrumb: [{ name: "API" }, { name: "Keys" }],
              },
              component: () => import("@/views/wrapper/api/Key"),
            },
            {
              path: "endpoints",
              name: "api_endpoint",
              meta: {
                breadcrumb: [{ name: "API" }, { name: "Endpoints" }],
              },
              component: () => import("@/views/wrapper/api/Endpoint"),
            },
          ],
        },
        {
          path: "/app/checker",
          component: () => import("@/views/wrapper/checker/Index"),

          children: [
            {
              path: "creatives",
              name: "checker_creative",
              meta: {
                breadcrumb: [{ name: "Checker" }, { name: "Creative Checker" }],
              },
              component: () => import("@/views/wrapper/checker/Creative"),
            },
            {
              path: "assets",
              name: "checker_asset",
              meta: {
                breadcrumb: [{ name: "Checker" }, { name: "Asset Checker" }],
              },
              component: () => import("@/views/wrapper/checker/Asset"),
            },
            {
              path: "campaigns",
              name: "checker_campaign",
              meta: {
                breadcrumb: [{ name: "Checker" }, { name: "Campaign Checker" }],
              },
              component: () => import("@/views/wrapper/checker/Campaign"),
            },
            {
              path: "creative-content-munge",
              name: "checker_creative_content_munge",
              meta: {
                breadcrumb: [
                  { name: "Checker" },
                  { name: "Creative Content Munge" },
                ],
              },
              component: () => import("@/views/wrapper/checker/CreativeContentMunge"),
            },
            {
              path: "budget-monitor",
              name: "checker_budget_monitor",
              meta: {
                breadcrumb: [
                  { name: "Checker" },
                  { name: "Budget Monitor" },
                ],
              },
              component: () => import("@/views/wrapper/checker/BudgetMonitor"),
            },
            {
              path: "line-items",
              name: "checker_line_item",
              meta: {
                breadcrumb: [
                  { name: "Checker" },
                  { name: "Line Item Checker" },
                ],
              },
              component: () => import("@/views/wrapper/checker/LineItem"),
            },
            {
              path: "redis",
              name: "checker_redis",
              meta: {
                breadcrumb: [
                  { name: "Checker" },
                  { name: "Redis" },
                ],
              },
              component: () => import("@/views/wrapper/checker/Redis"),
            },
            {
              path: "traffic-stats",
              name: "checker_traffic_stats",
              meta: {
                breadcrumb: [
                  { name: "Checker" },
                  { name: "Traffic Stats Checker" },
                ],
              },
              component: () => import("@/views/wrapper/checker/TrafficStats"),
            },
          ],
        },
        {
          path: "/app/fate",
          component: () => import("@/views/wrapper/fate/Index"),

          children: [
            {
              path: "creative-videos",
              name: "creative_video",
              meta: {
                breadcrumb: [{ name: "FATE" }, { name: "Creative Videos" }],
              },
              component: () => import("@/views/wrapper/fate/CreativeVideo"),
            },
          ],
        },
        {
          path: "/app/miscellaneous",
          component: () => import("@/views/wrapper/miscellaneous/Index"),

          children: [
            {
              path: "bid-modifiers",
              name: "miscellaneous_bid_modifiers",
              meta: {
                breadcrumb: [{ name: "Miscellaneous" }, { name: "Bid Modifiers" }],
              },
              component: () => import("@/views/wrapper/miscellaneous/BidModifier"),
            },
            {
              path: "cities",
              name: "miscellaneous_cities",
              meta: {
                breadcrumb: [{ name: "Miscellaneous" }, { name: "Cities" }],
              },
              component: () => import("@/views/wrapper/miscellaneous/City"),
            },
            {
              path: "countries",
              name: "miscellaneous_countries",
              meta: {
                breadcrumb: [{ name: "Miscellaneous" }, { name: "Countries" }],
              },
              component: () => import("@/views/wrapper/miscellaneous/Country"),
            },
            {
              path: "dashboards",
              name: "miscellaneous_dashboards",
              meta: {
                breadcrumb: [{ name: "Miscellaneous" }, { name: "Dashboards" }],
              },
              component: () => import("@/views/wrapper/miscellaneous/Dashboard"),
            },
            {
              path: "delivery-modifiers",
              name: "miscellaneous_delivery_modifiers",
              meta: {
                breadcrumb: [{ name: "Miscellaneous" }, { name: "Delivery Modifiers" }],
              },
              component: () => import("@/views/wrapper/miscellaneous/DeliveryModifier"),
            },
            {
              path: "inventory-sources",
              name: "miscellaneous_inventory_sources",
              meta: {
                breadcrumb: [{ name: "Miscellaneous" }, { name: "Inventory Sources" }],
              },
              component: () => import("@/views/wrapper/miscellaneous/InventorySource"),
            },
            {
              path: "languages",
              name: "miscellaneous_languages",
              meta: {
                breadcrumb: [{ name: "Miscellaneous" }, { name: "Languages" }],
              },
              component: () => import("@/views/wrapper/miscellaneous/Language"),
            },
            {
              path: "metros",
              name: "miscellaneous_metros",
              meta: {
                breadcrumb: [{ name: "Miscellaneous" }, { name: "Metros" }],
              },
              component: () => import("@/views/wrapper/miscellaneous/Metro"),
            },
            {
              path: "operating-systems",
              name: "miscellaneous_operating_systems",
              meta: {
                breadcrumb: [{ name: "Miscellaneous" }, { name: "Operating Systems" }],
              },
              component: () => import("@/views/wrapper/miscellaneous/OperatingSystem"),
            },
            {
              path: "platforms",
              name: "miscellaneous_platforms",
              meta: {
                breadcrumb: [{ name: "Miscellaneous" }, { name: "Platforms" }],
              },
              component: () => import("@/views/wrapper/miscellaneous/Platform"),
            },
            {
              path: "regions",
              name: "miscellaneous_regions",
              meta: {
                breadcrumb: [{ name: "Miscellaneous" }, { name: "Regions" }],
              },
              component: () => import("@/views/wrapper/miscellaneous/Region"),
            },
            {
              path: "report-fields",
              name: "miscellaneous_report_fields",
              meta: {
                breadcrumb: [{ name: "Miscellaneous" }, { name: "Report Fields" }],
              },
              component: () => import("@/views/wrapper/miscellaneous/ReportField"),
            },
            {
              path: "reports",
              name: "miscellaneous_reports",
              meta: {
                breadcrumb: [{ name: "Miscellaneous" }, { name: "Reports" }],
              },
              component: () => import("@/views/wrapper/miscellaneous/Report"),
            },
          ],
        },
      ],
    },
    {
      path: "/app/sessions",
      component: () => import("@/views/wrapper/sessions/Sessions"),

      children: [
        {
          path: "login",
          component: () => import("@/views/wrapper/sessions/Login"),
        },
        {
          path: "error",
          component: () => import("@/views/wrapper/sessions/Error"),
        },
        {
          path: "forgot",
          component: () => import("@/views/wrapper/sessions/Forgot"),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch("changeThemeLoadingState", true);
  }

  next();
});

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);

  if (store.getters.loggedInUser) store.dispatch("getActivityLogs");
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
});

export default router;
